#container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-height: 100vh;
  max-width: 1200px;
  margin: 0px auto;
}

.game-container {
  background-color: var(--background-color);

}
.content {
  background-color: #EEEEEE;
}

.content-article {
  max-width: 800px;
  padding: 40px 30px;
  margin: 0px auto;
}

.content-article p {
    color: #222831;
    font-size: 16px;
    text-align: left;
    font-weight: 500;
}
.content-article a {
  position: relative;
  z-index: 9;
  font-weight: bold;
  text-decoration: none;
  color: #000;
}
.content-article a:hover:before {
  left: -5px;
  bottom: 0;
  height: 110%;
  width: calc(100% + 10px);
}
.content-article a::before {
  content: "";
  background-color: rgb(148 203 206 / 49%);
  position: absolute;
  left: 0.5rem;
  bottom: 0;
  width: 100%;
  height: 10px;
  z-index: -1;
  transition: all 0.3s ease-in-out;
}
.how-to {
  background: #76ABAE;
  padding: 5px 20px;
  border-radius: 8px;
}

.how-to ol {
  padding: 0 17px;

}
.how-to ol li {
  padding: 3px 0;
}

:root {
  --background-color: #76ABAE;
  --past-color: rgb(118 171 174 / 43%);
}

body {
  /* font-family: Arial, Helvetica, sans-serif; */
  font-family: "Open Sans", sans-serif;

  margin: 0px;
}

path, circle, rect {
  stroke-width: 5px;
}

text {
  font-size: 60px;
  text-anchor: middle;
}

p {
  font-size: 24px;
  text-align: center;
}

p.error {
  font-size: 16px;
}

span.join {
  color: #222831;
}

.row {
  display: flex;
  width: 100%;
  text-align: center;
  max-height: 50svh;
}

svg {
  margin: auto;
}

path, circle, rect {
  stroke-width: 5px;
  stroke: #222831;
}

.active {
  stroke: var(--background-color);
}

path.active {
  stroke-dasharray: 4% 4%;
}

path.past {
  stroke: var(--past-color);
}

circle {
  fill: white;
}

circle.past {
  fill: var(--background-color);
}

circle.lead {
  fill: #222831;
  stroke: var(--background-color);
}

text {
  font-size: 45px;
  text-anchor: middle;
  font-family: system-ui;
  fill: white;
}

text.past,text.active {
  fill: #222831;
}

text.active {
  font-weight: bold;
}

input {
  background-color: var(--background-color);
  border: none;
  font-size: 40px;
  border-bottom: 2px solid;
  margin-bottom: 25px;
  outline: none;
  width: 100%;
  text-align: center;
  padding: 20px 0;
}

button {
  font-size: 16px;
  color: #EEEEEE;
  -webkit-text-fill-color: #EEEEEE;
  background-color: #222831;
  border: 2px solid #222831;
  border-radius: 50px;
  padding: 7px 18px;
  margin: 5px;
  cursor: pointer;
  transition: ease-in-out .3s;

}
button:hover {
  background-color: #31363F;
  border-color: #31363F;
}

.banner {
  height: 3rem;
  display: flex;
  justify-content: center;
  transition: font-size 0.5s;
  align-items: center;
}

.loser {
  font-size: 0rem;
}

.winner {
  font-size: 1.5rem;
  transition-timing-function: ease-out;
}

.modal {
  display: flex;
  position: absolute;
  top: 0;
  background-color: var(--past-color);
  height: 100svh;
  margin: 10px;
  border-radius: 1em;
  text-align: center;
}

.modal p {
  font-size: 16px;
  text-align: left;
  padding: 0rem 1rem;
  margin: .5rem 1rem;
}

.modal button {
  margin-left: 1rem;
}

.modal img {
  margin-top: 20px;
  margin-right: 7.5px;
}

.input-container {
  margin:0px auto;
  max-width: 500px;
}
@media only screen and (max-width: 600px) {
  input {
    font-size: 30px;
  }
}
@media only screen and (max-width: 1025px) {
  #container {
    flex-direction: column;
  }
}

